<template>
  <div class="warning-overlay-container">
    <div>
      <div>
        <h4 class="warning-overlay-title">Incomplete Inputs</h4>
      </div>
      <div>
        Please select a sprinkler and a layout, and enter numeric values for all inputs.
      </div>
      <div class="close-btn" @click="handleClose">
        CLOSE
      </div>
    </div>
  </div>
</template>

<script>
import { useModalStore } from '@/stores/ModalStore';

export default {
  data () {
    return {
      appFunction: 'units',
    };
  },
  methods: {
    handleLogoutStyle () {
      return {
        'background-color': 'var(--nelson-blue)',
        'width': '120px',
        'float': 'right',
      };
    },
    handleClose () {
      useModalStore().hideModal();
    },
  }
};
</script>

<style scoped>
.warning-overlay-container {
  background-color: #FFFFFF;
  width: 500px;
  height: 300px;
  text-align: center;
  margin-right: 40%;
  margin-top: 10%;
}
.warning-overlay-title {
  text-align: center;
  height: 50px;
}
.close-btn {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 70%;
  color: white;
  justify-content: center;
  font-weight: 600;
  cursor: pointer;
  background-color: #00bd5e;
  margin-top: 15%;
  margin-right: 15%;
  margin-left: 15%;
}
.close-btn:hover {
  background-color: #00b254;
}
</style>
