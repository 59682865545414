import { defineStore } from 'pinia';
import { useSprinklerStore } from '@/stores/SprinklerStore';

export const usePrintStore = defineStore('print', {
  state () {
    return {
      letterRatio: {
        long: 1.29411764706,
        short: 0.77272727272,
        base: 1260,
      }, // 0.77272727272,
      a4Ratio: {
        long: 1.414285714285714,
        short: 0.70707070707,
        base: 1400
      },
      size: 'letter', // a4 / letter
      orientation: 'landscape', // portrait / landscape
      printIsOpen: false,
      printDebug: 0,
      pageScale: 1,
      printInput: {
        notes: 'sample notes here',
        jobTitle: 'test'
      },
      printData: [
        // TODO: need to validate and replace incorrect values/references
        // TODO: *** means highly unsure
        {
          group: 1,
          data: [
            { label: 'Product', value: 'Sprinkler' },
            { label: 'Model', value: 'Model' },
            { label: 'Plate', value: 'Plate' },
            { label: 'Trajectory', value: 'Traj' },
            { label: 'Nozzle', value: 'Nozzle' },
            { label: 'Pressure', value: 'Pressure', unit: 'pressure' }
          ]
        },
        {
          group: 2,
          data: [
            { label: 'Flow', value: 'FlowRate', unit:'flow' },
            { label: 'Riser Ht', value: 'RiserHt', unit:'height' },
            { label: 'Mins/Rev', value: 'MinsPerRev', unit: 'mins' },
            { label: 'Stream Ht', value: 'StreamHeight', unit:'height' },
            { label: 'Wetted Radius', value: 'Radius', unit: 'length' },
            { label: 'Wind', value: 'WndSpd', unit: 'speed' }
          ]
        },
        {
          group: 3,
          data: [
            { label: 'Spacing', value: 'Spacing', unit: 'spacing' },
            { label: 'Offset', value: 'Offset', unit: 'length' },
            { label: 'Layout', value: 'Layout' },
            { label: 'Theor Precip', value: 'TheoreticalPrecipRate', unit: 'precip' },
            { label: 'Flow/Area', value: 'TheoreticalPrecipRatePerAcre', unit: 'rate' }, // ***
            { label: 'Area/Sprinkler', value: 'AreaPerSprinkler', unit: 'area' }
          ]
        },
        {
          group: 4,
          data: [
            { label: 'CU', value:'CU', unit: '%' },
            { label: 'DU', value:'DU', unit: '%' },
            { label: 'SC', value:'SC', unit: '%' },
            { label: '% Overlap', value:'PcentOlap' },
            { label: 'Mean Precip', value:'AppRate', unit: 'precip' }, // ***
            { label: 'Min Precip', value:'MinRate', unit: 'precip' }, // ***
            { label: 'Max Precip', value:'MaxRate', unit: 'precip' } // ***
          ]
        },
      ],
      treeVinePrintData: [
        {
          group: 'strips',
          data: [
            { label: 'CU', value: 'StatsCU' },
            { label: 'DU', value: 'StatsDU' },
            { label: 'SC', value: 'StatsSC' },
            { label: 'Mean', value: 'StatsAppRate', unit: 'precip' },
            { label: 'Min', value: 'StatsMinRate', unit: 'precip' },
            { label: 'Max', value: 'StatsMaxRate', unit: 'precip' },
            { label: '% Water', value: 'PercentWaterStrip' },
          ]
        },
        {
          group: 'trees',
          data: [
            { label: 'CU', value: 'TreeStatsCU' },
            { label: 'DU', value: 'TreeStatsDU' },
            { label: 'SC', value: 'TreeStatsSC' },
            { label: 'Mean', value: 'TreeStatsAppRate', unit: 'precip' },
            { label: 'Min', value: 'TreeStatsMinRate', unit: 'precip' },
            { label: 'Max', value: 'TreeStatsMaxRate', unit: 'precip' },
            { label: '% Water', value: 'PercentWaterTree' },
          ]
        },
      ],
      printQueue: [],
      currentPrintPage: 0
    };
  },
  getters: {
    getPrintIsOpen () {
      return this.printIsOpen;
    },
    getRatio () {
      return this.size === 'letter' ? this.letterRatio : this.a4Ratio;
    },
    getSize () {
      return this.size;
    },
    getOrientation () {
      return this.orientation;
    },
    getPageScale () {
      return this.pageScale;
    },
    getJobTitle () {
      return this.printInput.jobTitle;
    },
    getJobNotes () {
      return this.printInput.notes;
    },
    getPrintData () {
      return this.printData;
    },
    getTreeVinePrintData () {
      return this.treeVinePrintData;
    },
    getPrintQueue () {
      let arr = useSprinklerStore().records.filter((item) => {
        if (item.isMarked) {
          return item;
        }
      });
      return arr;
    },
    getCurrentPrintPage () {
      return this.currentPrintPage;
    },
    getTotalPrintPages () {
      return this.getPrintQueue.length;
    },
    getDimensions () {
      let sizeRatio = (this.size === 'letter') ? this.letterRatio : this.a4Ratio;
      return this.orientation === 'landscape' ? {
        width: sizeRatio.base,
        height: sizeRatio.base * sizeRatio.short
      } : {
        width: sizeRatio.base * sizeRatio.short,
        height: sizeRatio.base
      };
    }
  },
  actions: {
    setPrintIsOpen (bool) {
      this.printIsOpen = bool;
    },
    setSize (type) {
      this.size = type;
    },
    setOrientation (type) {
      this.orientation = type;
    },
    setPageScale (val) {
      this.pageScale = val;
    },
    setJobTitle (val) {
      this.printInput.jobTitle = val;
    },
    setJobNotes (val) {
      this.printInput.notes = val;
    },
    addToPrintQueue (record) {
      this.printQueue.push(record);
    },
    clearPrintQueue () {
      this.printQueue = [];
    },
    selectLastInPrintQueue () {
      this.printQueue = [this.printQueue.pop()];
    },
    showPrintPage (num) {
      this.currentPrintPage += num;
    }
  }
});