<template>
  <div class="tree-vine-holder">
    <div class="tv-label">Tree/Vine Spacing</div>
    <div class="tree-details-holder">
      <div class="td-left">
        <PrintSprinklerData :record="getRecord" :data="{label:'Tree Spacing', value: 'TreeSpacing', unit: 'spacing' }"></PrintSprinklerData>
        <PrintSprinklerData :record="getRecord" :data="{label:'Tree Layout', value: 'TreeLayout'}"></PrintSprinklerData>
      </div>
      <div class="td-right">
        <PrintSprinklerData :record="getRecord" :data="{label:'Tree Diameter', value: 'TreeDiameter', unit: 'length'}"></PrintSprinklerData>
        <PrintSprinklerData :record="getRecord" :data="{label:'Strip Width', value: 'StripWidth', unit: 'length'}"></PrintSprinklerData>
      </div>
    </div>
    <div class="tv-chart-holder">
      <TreeVineSpacingChart></TreeVineSpacingChart>
    </div>
  </div>
</template>

<script>
import PrintSprinklerData from '@/components/print/sheet_components/PrintSprinklerData';
import TreeVineSpacingChart from '@/components/print/sheet_components/TreeVineSpacingChart';

export default {
  name: 'PrintTreeVineSpacing',
  components: { TreeVineSpacingChart, PrintSprinklerData },
  props: [
    'record'
  ],
  computed: {
    getRecord () {
      return this.$props.record;
    }
  }
};
</script>

<style scoped>
.tree-vine-holder {
  border: .5px solid #231F20;
  margin: 20px;
}
.tv-label {
  font-weight: 600;
  font-size: 80%;
  padding-left:6px;
  padding-right:6px;
  background-color: white;
  position: relative;
  top:-10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 20px;
  margin-left: 20px;
}
.tree-details-holder {
  display: flex;
  width: 100%;
  padding:20px;
}
.td-left {
  width: 50%;
}
.td-right {
  width: 50%;
}
.tv-chart-holder {
  width: 100%;
}
</style>