<template>
  <div class="print-sheet-title">
    Notes
  </div>
  <div class="print-notes">
    {{ getNotes }}
  </div>
</template>

<script>
import { usePrintStore } from '@/stores/PrintStore';

export default {
  name: 'PrintNotes',
  computed: {
    getNotes () {
      return usePrintStore().getJobNotes;
    }
  }
};
</script>

<style scoped>
.print-notes-input {
  flex: 1;
  margin: 10px;
}
.print-notes {
  border: .5px solid #231F20;
  font-size: 80%;
  margin-right: 20px;
  padding: 10px;
  flex: 1;
}
</style>