<template>
  <div class="badge" @click="this.handleSettings">
    <label id="ub-initialsLabel">{{ this.initials }}</label>
  </div>
</template>

<script>
import { useIdentityStore } from '@/stores/IdentityStore';
import { useModalStore } from '@/stores/ModalStore';

export default {
  computed: {
    initials () {
      const user = useIdentityStore().User;
      return user ? user.initials : '';
    }
  },
  methods: {
    handleSettings () {
      useModalStore().showModal('settings');
    }
  }
};
</script>

<style scoped>
.badge {
  aspect-ratio: 1/1;
  cursor: pointer;
  background-color: black;
  border-radius: 25px;
  color: white;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: .85em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>