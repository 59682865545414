<template>
  <div ref="overlapSvgHolder" class="overlap-svg-holder">
    <canvas id="og-canvasGrid" ref="canvasGrid" :width="getSvgWidth" :height="getSvgHeight"></canvas>
  </div>
</template>

<script>
import { useGridStore } from '@/stores/GridStore';
import { useSettingsStore } from '@/stores/SettingsStore';
import { useSprinklerStore } from '@/stores/SprinklerStore';
import { storeToRefs } from 'pinia/dist/pinia';
import * as UnitConversion from '@/services/UnitConversionService.js';

export default {
  setup () {
    const { selectedLineColorIndex, selectedScaleModeIndex, selectedThemeIndex, viewOptions, fixedModeIncrement, fixedModeMin } = storeToRefs(useGridStore());
    const { selectedRecord } = storeToRefs(useSprinklerStore());
    const { precipUnit } = storeToRefs(useSettingsStore());
    return {
      selectedLineColorIndex, selectedScaleModeIndex, selectedThemeIndex, viewOptions, selectedRecord, fixedModeIncrement, fixedModeMin, precipUnit
    };
  },
  props: [
    'record'
  ],
  mounted () {
    this.handleResize();
    this.drawCanvas();
    window.addEventListener('resize', this.handleResize);

    // TODO: watch this
    const resizeObserver = new ResizeObserver(this.handleResize);
    resizeObserver.observe(document.getElementById('dataGridHolder'));
  },
  watch: {
    precipUnit: {
      handler () {
        this.drawCanvas();
      },
      deep: false
    },
    fixedModeIncrement: {
      handler () {
        this.drawCanvas();
      },
      deep: false
    },
    fixedModeMin: {
      handler () {
        this.drawCanvas();
      },
      deep: false
    },
    selectedScaleModeIndex: {
      handler () {
        this.drawCanvas();
      },
      deep: false
    },
    selectedLineColorIndex: {
      handler () {
        this.drawCanvas();
      },
      deep: false
    },
    selectedRecord: {
      handler () {
        this.drawCanvas();
      },
      deep: true
    },
    selectedThemeIndex: {
      handler () {
        this.drawCanvas();
      },
      deep: false
    },
    viewOptions: {
      handler () {
        this.drawCanvas();
      },
      deep: true
    },
  },
  updated () {
    this.handleResize();
    this.drawCanvas();
  },
  computed: {
    getBuffer () {
      return 20;
    },
    getLineColor () {
      return useGridStore().getSelectedColor;
    },
    getSprPerRow () {
      return useSprinklerStore().sprPerRow;
    },
    getNumRows () {
      return parseFloat(useSprinklerStore().numRows);
    },
    getSvgWidth () {
      if (this.$props.record === null) { return 0; }
      let w = this.cans[this.cans.length - 1].x + this.gridScaleUnit + (this.getBuffer * 2);
      return w;// + 'px';
    },
    getSvgHeight () {
      if (this.$props.record === null) { return 0; }
      let w = this.cans[this.cans.length - 1].y + this.gridScaleUnit + (this.getBuffer * 2);
      return w;// + 'px';
    },
    grid () {
      if (useSprinklerStore().records.length === 0) { return []; }
      return this.$props.Grid;
    },
    showCanValues () {
      return useGridStore().viewOptions.grid;
    },
    showContours () {
      return useGridStore().viewOptions.contours && (this.grid.length !== 0);
    },
    cans () {
      if (this.$props.record == null) { return []; }
      const record = this.$props.record;
      const gridStore = useGridStore();
      var maxValue = record.MaxRate;
      var minValue = record.MinRate;
      if (gridStore.selectedScaleModeIndex === 1) {
        const fixedModeIncrement = gridStore.fixedModeIncrement;
        const fixedModeMin = gridStore.fixedModeMin;
        minValue = parseFloat((fixedModeMin.toString().slice(-1) === '.') ? fixedModeMin.toString().slice(0, -1) : fixedModeMin);
        const increment = parseFloat((fixedModeIncrement.toString().slice(-1) === '.') ? fixedModeIncrement.toString().slice(0, -1) : fixedModeIncrement);
        maxValue = minValue + (increment * (gridStore.numberOfLegendValues - 1));
      }
      var cans = [];
      // StartColCellNum, should start our iteration from this column
      for (var i = 0; i < record.Grid.length; i = i + record.CanSpacing) {// row
        for (var j = 0; j < record.Grid[i].length; j = j + record.CanSpacing) {// column
          const precipValue = gridStore.selectedScaleModeIndex === 1 ? this.filterPrecip(record.Grid[i][j]) : record.Grid[i][j];
          var theCan = {
            x: j * this.gridScaleUnit,
            y: i * this.gridScaleUnit,
            value: this.filterPrecip(record.Grid[i][j]).toFixed(2),
            color: this.hexFromValue(precipValue, maxValue, minValue)
          };
          cans.push(theCan);
        }
      }
      return cans;
    },

    // TREES
    treeColor () {
      if (useGridStore().getViewOptions.contours) {
        return 'cyan';
      } else {
        return 'darkgreen';
      }
    },

    // RADII
    gridScaleUnit () {
      return 10; // note: this is how big the squares are (in pixels) when the grid is draw. This value is a constant and the grid canvas now scales instead of the grid unit
    }
  },
  methods: {
    filterPrecip (precip) {
      return parseFloat(UnitConversion.FilterPrecip(precip, useSettingsStore().precipUnit));
    },
    handleResize () {
      const gridStore = useGridStore();
      if (this.$refs.overlapSvgHolder == null) { return; }
      gridStore.gridHeight = this.$refs.overlapSvgHolder.clientHeight;
      gridStore.gridWidth = this.$refs.overlapSvgHolder.clientWidth;
      let ratio = this.getSvgWidth / this.getSvgHeight;
      let newWidth = gridStore.gridWidth;
      let newHeight = gridStore.gridWidth / ratio;
      if (newHeight > gridStore.gridHeight) {
        newHeight = gridStore.gridHeight;
        newWidth = gridStore.gridHeight * ratio;
      }
      this.$refs.canvasGrid.style.width = newWidth + 'px';
      this.$refs.canvasGrid.style.height = newHeight + 'px';
    },
    drawCanvas () {// 0,0 is top, left corner
      let canvas = this.$refs.canvasGrid;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, this.getSvgWidth, this.getSvgHeight);
      ctx.beginPath();
      ctx.moveTo(this.getBuffer, this.getBuffer);
      ctx.lineTo(this.getSvgWidth - this.getBuffer, this.getBuffer);
      ctx.lineTo(this.getSvgWidth - this.getBuffer, this.getSvgHeight - this.getBuffer);
      ctx.lineTo(this.getBuffer, this.getSvgHeight - this.getBuffer);
      ctx.lineTo(this.getBuffer, this.getBuffer);
      ctx.closePath();
      ctx.clip();
      const record = this.$props.record; // sprinklerStore.selectedRecord;
      if (record == null) { return; }
      const pixelsPerUnit = this.gridScaleUnit;

      // ----- START CONTOURS
      const gridStore = useGridStore();
      if (gridStore.getViewOptions.contours) {
        for (let a = 0; a < this.cans.length; a++) {
          ctx.fillStyle = this.cans[a].color;
          ctx.fillRect(this.cans[a].x + this.getBuffer, this.cans[a].y + this.getBuffer, pixelsPerUnit * record.CanSpacing, pixelsPerUnit * record.CanSpacing);
          ctx.lineWidth = 1;
          ctx.strokeStyle = '#00000077';
          ctx.strokeRect(this.cans[a].x + this.getBuffer, this.cans[a].y + this.getBuffer, pixelsPerUnit * record.CanSpacing, pixelsPerUnit * record.CanSpacing);
        }
      }
      // ----- END CONTOURS

      // ----- START GRID
      if (gridStore.getViewOptions.grid) {
        ctx.fillStyle = 'rgba(255,255,255,0.6)';
        ctx.fillRect(this.getBuffer, this.getBuffer, this.getSvgWidth - (this.getBuffer * 2), this.getSvgHeight - (this.getBuffer * 2));
        ctx.fillStyle = '#000000';
        const widthMod = Math.round(record.Grid[0].length * 50 / 101 / 10) * 10;
        const heightMod = Math.round(record.Grid.length * 20 / 61 / 10) * 10;
        const textSize = Math.round((record.Grid[0].length + record.Grid.length) * 15 / 162);
        for (let a = 0; a < this.cans.length; a++) {
          if (this.cans[a].x % widthMod === 0 && this.cans[a].y % heightMod === 0) {
            ctx.font = 'bold ' + textSize + 'px Heebo ';
            if (this.cans[a].x % (widthMod * 2) === 0) {
              ctx.font = textSize + 'px Heebo';
            }
            ctx.fillText(this.cans[a].value, this.cans[a].x + this.getBuffer, this.cans[a].y + this.getBuffer + 10);
          }
        }
      }
      // ----- END GRID

      let lineThickness = 4 * (Math.max(record.Grid.length, record.Grid[0].length) / 100);
      const sprinklerStore = useSprinklerStore();
      const yIncrement = sprinklerStore.filterLength(record.PipeSpacing) * pixelsPerUnit;
      var yPipeOffset = this.getBuffer + (pixelsPerUnit / 2);
      if (record.HasWateredRegion) {
        yPipeOffset += sprinklerStore.filterLength(sprinklerStore.distFromSide) * pixelsPerUnit;
      }

      // ---- START SPRINKLER PIPES
      for (let a = 0; a < this.getNumRows; a++) {
        const y = (a * yIncrement) + yPipeOffset;
        ctx.strokeStyle = this.getLineColor;
        ctx.lineWidth = lineThickness;
        ctx.setLineDash([]);
        ctx.beginPath();
        ctx.moveTo(this.getBuffer, y);
        ctx.lineTo(this.getSvgWidth - this.getBuffer, y);
        ctx.stroke();
        ctx.closePath();
      }
      // ----- END SPRINKLER PIPES

      // ----- START SPRINKLER ICONS and RADII
      for (let i = 0; i < record.SprinklerList.length; i++) {
        var x = 0;
        x = ((record.SprinklerList[i].X - record.StartColCellNum) * pixelsPerUnit) + this.getBuffer + 5;
        let y = ((record.SprinklerList[i].Y - record.StartRowCellNum) * pixelsPerUnit) + this.getBuffer + 5;
        ctx.strokeStyle = 'red';
        ctx.lineWidth = lineThickness;

        // Sprinkler Icon
        ctx.beginPath();
        ctx.arc(x, y, lineThickness * 3, 0, 2 * Math.PI);
        ctx.stroke();
        ctx.closePath();

        // Sprinkler Radii
        if (gridStore.getViewOptions.radii) {
          ctx.lineWidth = 2;
          ctx.strokeStyle = this.getLineColor;
          ctx.beginPath();
          ctx.arc(x, y, record.Radius * pixelsPerUnit, 0, 2 * Math.PI);
          ctx.setLineDash([15, 5]);
          ctx.stroke();
          ctx.closePath();
        }
      }
      // ----- END SPRINKLER ICONS and RADII

      // ----- START TREES
      if (gridStore.getViewOptions.trees && !record.HasWateredRegion) {
        sprinklerStore.calculateTreeRows();
        let treeXOffset = this.getBuffer;
        let treeYOffset = this.getSvgHeight + (pixelsPerUnit * (sprinklerStore.filterLength(sprinklerStore.pipeOrigin) - 0.5)) - this.getBuffer;
        const xIncrement = sprinklerStore.filterLength(sprinklerStore.treeSpacing) * pixelsPerUnit;
        const yIncrement = sprinklerStore.filterLength(sprinklerStore.getTreeRowSpacing) * pixelsPerUnit;
        for (let a = 0; a < sprinklerStore.numberOfTreeRows; a++) {
          let y = a * yIncrement;
          for (var b = 0; b < sprinklerStore.numberOfTreesInRow; b++) {
            let x = (b * xIncrement) + (sprinklerStore.treeXOffsetFor(a) * pixelsPerUnit);
            ctx.lineWidth = lineThickness;
            ctx.strokeStyle = this.treeColor;
            ctx.beginPath();
            ctx.arc(x + treeXOffset, treeYOffset - y, record.TreeDiameter / 2 * pixelsPerUnit, 0, 2 * Math.PI);
            ctx.setLineDash([5, 2]);
            ctx.stroke();
            ctx.closePath();
          }
        }
      }
      // ----- END TREES

      // ----- START STRIPS
      if (gridStore.getViewOptions.strips && !record.HasWateredRegion) {
        let stripYOffset = this.getSvgHeight + (pixelsPerUnit * (sprinklerStore.filterLength(sprinklerStore.pipeOrigin) - (sprinklerStore.filterLength(sprinklerStore.irrStripWidth) / 2) - 0.5)) - this.getBuffer;
        const yIncrement = sprinklerStore.filterLength(sprinklerStore.getTreeRowSpacing) * pixelsPerUnit;
        const stripLength = this.getSvgWidth - (this.getBuffer * 2);
        const stripWidth = sprinklerStore.filterLength(sprinklerStore.irrStripWidth) * pixelsPerUnit;
        for (let a = 0; a < sprinklerStore.numberOfTreeRows; a++) {
          let y = a * yIncrement;
          ctx.beginPath();
          ctx.fillStyle = '#ffffff5a';
          ctx.fillRect(this.getBuffer, stripYOffset - y, stripLength, stripWidth);
          ctx.setLineDash([15, 5]);
          ctx.stroke();
          ctx.closePath();
        }
      }
      // ----- END STRIPS

      if (record.HasWateredRegion) {
        const lineWidth = pixelsPerUnit / 4;
        ctx.strokeStyle = '#ff0000';
        ctx.lineWidth = lineWidth;
        ctx.setLineDash([]);
        ctx.beginPath();
        ctx.moveTo(this.getBuffer + pixelsPerUnit - lineWidth, this.getBuffer + pixelsPerUnit - lineWidth);
        ctx.lineTo(this.getSvgWidth - this.getBuffer - pixelsPerUnit + lineWidth, this.getBuffer + pixelsPerUnit - lineWidth);
        ctx.lineTo(this.getSvgWidth - this.getBuffer - pixelsPerUnit + lineWidth, this.getSvgHeight - this.getBuffer - pixelsPerUnit + lineWidth);
        ctx.lineTo(this.getBuffer + pixelsPerUnit - lineWidth, this.getSvgHeight - this.getBuffer - pixelsPerUnit + lineWidth);
        ctx.lineTo(this.getBuffer + pixelsPerUnit - lineWidth, this.getBuffer + pixelsPerUnit - lineWidth - 1);
        ctx.stroke();
        ctx.closePath();
      }
    },
    hexFromValue (value, max, min) {
      return useGridStore().hexFromValue(value, max, min);
    },
  }
};
</script>

<style scoped>
.dark-cell {
  color: #555555;
  width: 30px;
  height: 20px;
}
.light-cell {
  color: #888888;
  width: 30px;
  height: 20px;
}
.overlap-svg-holder {
  width: 100%;
  overflow: hidden;
}
.overlap-svg-mask {
  width: 100%;
  overflow: hidden;
}
.canvas-grid-h {
  width: 100% !important;
  height: auto !important;
}
.canvas-grid-w {
  width: auto !important;
  height: 100% !important;
}
</style>