<template>
  <div>
    <div class="row">
      <div class="unit-column">
        <div class="unit-title">HEIGHT</div>
        <div v-for="n in heightOptions()" :key="n" class="unit-option">
          <input :id="'u-height-'+ formatOption(n)" type="radio" :value="n" v-model="this.heightUnit">
          <label :for="n">{{ n }}</label>
        </div>
      </div>
      <div class="unit-column">
        <div class="unit-title">LENGTH</div>
        <div v-for="n in lengthOptions()" :key="n" class="unit-option">
          <input :id="'u-length-'+ formatOption(n)" type="radio" :value="n" v-model="this.lengthUnit">
          <label :for="n">{{ n }}</label>
        </div>
      </div>
      <div class="unit-column">
        <div class="unit-title">PRESSURE</div>
        <div v-for="n in pressureOptions()" :key="n" class="unit-option">
          <input :id="'u-pressure-'+ formatOption(n)" type="radio" :value="n" v-model="this.pressureUnit">
          <label :for="n">{{ n }}</label>
        </div>
      </div>
      <div class="unit-column">
        <div class="unit-title">AREAS</div>
        <div v-for="n in areaOptions()" :key="n" class="unit-option">
          <input :id="'u-areas-'+ formatOption(n)" type="radio" :value="n" v-model="this.areaUnit">
          <label :for="n">{{ n }}</label>
        </div>
      </div>
    </div>
    <hr/>
    <div class="row">
      <div class="unit-column">
        <div class="unit-title">FLOW</div>
        <div v-for="n in flowOptions()" :key="n" class="unit-option">
          <input :id="'u-flow-'+ formatOption(n)" type="radio" :value="n" v-model="this.flowUnit">
          <label :for="n">{{ n }}</label>
        </div>
      </div>
      <div class="unit-column">
        <div class="unit-title">RATE</div>
        <div v-for="n in rateOptions()" :key="n" class="unit-option">
          <input :id="'u-rate-'+ formatOption(n)" type="radio" :value="n" v-model="this.rateUnit">
          <label :for="n">{{ n }}</label>
        </div>
      </div>
      <div class="unit-column">
        <div class="unit-title">PRECIP</div>
        <div v-for="n in precipOptions()" :key="n" class="unit-option">
          <input :id="'u-precip-'+ formatOption(n)" type="radio" :value="n" v-model="this.precipUnit">
          <label :for="n">{{ n }}</label>
        </div>
      </div>
      <div class="unit-column">
        <div class="unit-title">SPEED</div>
        <div v-for="n in speedOptions()" :key="n" class="unit-option">
          <input :id="'u-speed-'+ formatOption(n)" type="radio" :value="n" v-model="this.speedUnit">
          <label :for="n">{{ n }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useSettingsStore } from '@/stores/SettingsStore.js';
import { mapStores } from 'pinia';

export default {
  computed: {
    ...mapStores(useSettingsStore),
    heightUnit: {
      get () {
        return this.settingsStore.heightUnit;
      },
      set (value) {
        this.settingsStore.setHeightUnit(value);
      },
    },
    lengthUnit: {
      get () {
        return this.settingsStore.lengthUnit;
      },
      set (value) {
        this.settingsStore.setLengthUnit(value);
      },
    },
    pressureUnit: {
      get () {
        return this.settingsStore.pressureUnit;
      },
      set (value) {
        this.settingsStore.setPressureUnit(value);
      },
    },
    areaUnit: {
      get () {
        return this.settingsStore.areaUnit;
      },
      set (value) {
        this.settingsStore.setAreaUnit(value);
      },
    },
    flowUnit: {
      get () {
        return this.settingsStore.flowUnit;
      },
      set (value) {
        this.settingsStore.setFlowUnit(value);
      },
    },
    rateUnit: {
      get () {
        return this.settingsStore.rateUnit;
      },
      set (value) {
        this.settingsStore.setRateUnit(value);
      },
    },
    precipUnit: {
      get () {
        return this.settingsStore.precipUnit;
      },
      set (value) {
        this.settingsStore.setPrecipUnit(value);
      },
    },
    speedUnit: {
      get () {
        return this.settingsStore.speedUnit;
      },
      set (value) {
        this.settingsStore.setSpeedUnit(value);
      },
    },
  },
  methods: {
    formatOption (option) {
      return option.replace(/\s|\.|\//g, '-').replace(/#/g, '');
    },
    heightOptions () {
      return this.settingsStore.heightOptions;
    },
    lengthOptions () {
      return this.settingsStore.lengthOptions;
    },
    pressureOptions () {
      return this.settingsStore.pressureOptions;
    },
    areaOptions () {
      return this.settingsStore.areaOptions;
    },
    flowOptions () {
      return this.settingsStore.flowOptions;
    },
    rateOptions () {
      return this.settingsStore.rateOptions;
    },
    precipOptions () {
      return this.settingsStore.precipOptions;
    },
    speedOptions () {
      return this.settingsStore.speedOptions;
    },
  }
};
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: row;
}

.unit-column {
  display: flex;
  flex-direction: column;
  width: 25%;
}

.unit-title {
  color: var(--nelson-blue);
  padding: 10px;
  text-align: center;
}

.unit-option {
  color: #444444;
  display: flex;
  flex-direction: row;
  font-size: x-large;
}
</style>
