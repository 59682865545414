<template> 
  <div v-if="isShowing" id="modal-view" @click="handleBackgroundClick">
    <div class="modal-view-bg">
      <InputWarn class="input-warn" v-if="modalType === 'inputWarn'" />
      <ModalSettings class="modal-settings" v-if="modalType === 'settings'" />
      <TOSModal v-if="modalType === 'tos'" />
    </div>
  </div>
</template>

<script>
import { useModalStore } from '@/stores/ModalStore';
import InputWarn from '@/components/modal/InputWarn.vue';
import ModalSettings from '@/components/modal/ModalSettings.vue';
import TOSModal from '@/components/modal/TOSModal.vue';

export default {
  name: 'ModalView',
  components: {
    InputWarn,
    ModalSettings,
    TOSModal,
  },
  computed: {
    isShowing () {
      return useModalStore().getIsShowing;
    },
    modalType () {
      return useModalStore().getModalType;
    }
  },
  methods: {
    hideModal () {
      useModalStore().hideModal();
    },
    handleBackgroundClick () {
      if (this.modalType === 'tos' || this.modalType === 'settings') { return; }
      this.hideModal();
    },
  }
};
</script>

<style scoped>
.modal-view-bg {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3000;
}
.modal-sprinklers {
  justify-self: flex-end !important;
}
</style>
