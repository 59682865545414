<template>
    <div class="grid-option-layout">
      <div class="column-choices">
        <div class="data-option-title centered">{{ getTitle }}</div>
        <div class="data-option-divider"></div>
        <SGOption
            v-for="(index, option) in getOptions"
            :key="this.$props.type + '_' + index"
            :type="this.$props.type"
            :option="option"
        />
    </div>
  </div>
</template>

<script>
import { useGridStore } from '@/stores/GridStore';
import { useSprinklerStore } from '@/stores/SprinklerStore';
import SGOption from '@/components/sprinkler_grid_components/SGOption';

export default {
  components: {
    SGOption
  },
  props: [
    'type'
  ],
  computed: {
    getOptions () {
      const gridStore = useGridStore();
      switch (this.$props.type) {
        case 'view':
          return gridStore.viewOptions;
        case 'theme':
          return gridStore.themes;
        case 'lineColor':
          return gridStore.lineColors;
        case 'scaleMode':
          return gridStore.scaleModes;
        default:
          return [];
      }
    },
    getTitle () {
      return useSprinklerStore().getTitle(this.$props.type);
    },
  },
  methods: {
/*    setTheme (name) {
      this.selectedScaleMode = (name == 'Standard') ? 'Fixed' : 'Sliding';
      this.selectedThemeName = name;
    }*/
  }
};
</script>

<style>
.data-option-divider {
  height: 5px;
  background-color: #00bd5e;
}
</style>

<style scoped>
.grid-option-layout {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.column-choices {
  padding: 6px;
  width: 140px;
}



</style>