<template>
  <div class="print-sheet-title">
    Sprinkler
  </div>
  <div class="psd-group-holder" :class="getOrientation">
    <div class="psd-group" v-for="(group, indexG) in getPrintDataGroups" :key="indexG">
      <PrintSprinklerData v-for="(data, indexPS) in group.data" :key="indexPS" :data="data" :record="this.$props.record"></PrintSprinklerData>
    </div>
  </div>
</template>

<script>
import PrintSprinklerData from '@/components/print/sheet_components/PrintSprinklerData';
import { usePrintStore } from '@/stores/PrintStore';

export default {
  name: 'PrintSprinkler',
  components: { PrintSprinklerData },
  props: [
    'record'
  ],
  computed: {
    getPrintDataGroups () {
      return usePrintStore().getPrintData;
    },
    getOrientation () {
      return (usePrintStore().getOrientation === 'portrait') ? 'psd-group-holder-portrait' : '';
    },
  }
};
</script>

<style scoped>
.psd-group {
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}
.psd-group-holder {
  display: flex;
  flex-wrap: wrap;
  max-height:400px;
}
.psd-group-holder-portrait {
  max-height: none;
}
</style>