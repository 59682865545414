import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import VueProgressBar from '@aacassandra/vue3-progressbar';
import { createPinia } from 'pinia';
import { loadFonts } from '@/plugins/webfontloader';
import '@/assets/css/styles.css';

loadFonts();

const options = {
  color: 'orange',
  failedColor: '#874b4b',
  thickness: '15px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false,
  autoFinish: false
};

const app = createApp(App)
  .use(createPinia())
  .use(router)
  .use(VueProgressBar, options);

app.config.globalProperties.$title = 'OverlapPro';
app.config.globalProperties.$version = '2.0.5';

app.mount('#app');