import axios from 'axios';

 const baseUrl = 'https://services.nelsonirrigation.com/OLDS/api/';
// const baseUrl = 'https://dev-services.nelsonirrigation.com/OLDS/api/';
// const baseUrl = 'http://localhost:58742/api/';
const apiClient = axios.create({
  baseUrl: baseUrl,
  withCredentials: false,
});

export default {
  data () {
    return {
      token: null,
      currentInstance: null
    };
  },
  setAuthHeader (_token) {
    this.token = _token;
  },
  getMenu () {
    return apiClient.post(baseUrl + 'Record/menu', null, {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'text/plain',
        Authorization: 'Bearer ' + this.token,
      },
    });
  },
  getSprinklerTypes () {
    return apiClient.post(baseUrl + 'Record/menu', null, {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'text/plain',
        Authorization: 'Bearer ' + this.token,
      },
    });
  },
  getModels (selection) {
    return apiClient.post(baseUrl + 'Record/models', selection, {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'text/plain',
        Authorization: 'Bearer ' + this.token,
      },
    });
  },
  getTrajectories (selection) {
    return apiClient.post(baseUrl + 'Record/trajectories', selection, {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'text/plain',
        Authorization: 'Bearer ' + this.token,
      },
    });
  },
  getNozzles (selection) {
    return apiClient.post(baseUrl + 'Record/nozzles', selection, {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'text/plain',
        Authorization: 'Bearer ' + this.token,
      },
    });
  },
  getPressures (selection) {
    return apiClient.post(baseUrl + 'Record/pressures', selection, {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'text/plain',
        Authorization: 'Bearer ' + this.token,
      },
    });
  },
  getRisers (selection) {
    return apiClient.post(baseUrl + 'Record/risers', selection, {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'text/plain',
        Authorization: 'Bearer ' + this.token,
      },
    });
  },
  getOrientations (selection) {
    return apiClient.post(baseUrl + 'Record/orientations', selection, {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'text/plain',
        Authorization: 'Bearer ' + this.token,
      },
    });
  },
  analyze (selection) {
    return apiClient.post(baseUrl + 'Record/analyze', selection, {
      headers: {
        'Content-Type': 'application/json',
        'accept': 'text/plain',
        Authorization: 'Bearer ' + this.token,
      },
    });
  },
};
