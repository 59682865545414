<template>
  <div class="option-icon-text" :id="'sgo-' + this.$props.type + '-' + getId" @click="handleOptionClick">
    <div class="option-icon-holder">
      <img :src="require('@/assets/svg/SVG/' + this.$props.type + '_' + getOption + '.svg')"/>
    </div>
    <div class="option-text">{{ getText }}</div>
    <div class="option-visibility-holder">
      <img :id="'sgo-' + this.$props.type + '-' + getId + '-enabled'" v-if="getOptionState" :src="require('@/assets/svg/SVG/' + getSelectedSVG + '.svg')"/>
      <img :id="'sgo-' + this.$props.type + '-' + getId + '-disabled'" v-if="!getOptionState" :src="require('@/assets/svg/SVG/' + getDeselectedSVG + '.svg')"/>
    </div>
  </div>
</template>

<script>
import { useGridStore } from '@/stores/GridStore';
import { useSprinklerStore } from '@/stores/SprinklerStore';

export default {
  name: 'SGOption',
  props: [
    'type',
    'option'
  ],
  computed: {
    getOptionState () {
      const gridStore = useGridStore();
      // TODO: optimize these to not use switches and use $props.type instead with if/else statement
      switch (this.$props.type) {
        case 'view':
          return gridStore.viewOptions[this.$props.option];
        case 'theme':
          return gridStore.selectedThemeIndex === this.$props.option;
        case 'lineColor':
          return gridStore.selectedLineColorIndex === this.$props.option;
        case 'scaleMode':
          return gridStore.selectedScaleModeIndex === this.$props.option;
        default:
          return false;
      }
    },
    getId () {
      return this.getOption;
    },
    getOption () {
      const gridStore = useGridStore();
      // TODO: optimize these to not use switches and use $props.type instead with if/else statement
      switch (this.$props.type) {
        case 'view':
          return this.$props.option;
        case 'theme':
          return gridStore.themes[this.$props.option].name.toLowerCase();
        case 'lineColor':
            return gridStore.lineColors[this.$props.option].name.toLowerCase();
        case 'scaleMode':
            return gridStore.scaleModes[this.$props.option].name.toLowerCase();
        default:
            return [];
      }
    },
    getText () {
      const gridStore = useGridStore();
      // TODO: optimize these to not use switches and use $props.type instead with if/else statement
      switch (this.$props.type) {
        case 'view':
          return this.getTitle(this.$props.option);
        case 'theme':
          return this.getTitle(gridStore.themes[this.$props.option].name.toLowerCase());
        case 'lineColor':
          return this.getTitle(gridStore.lineColors[this.$props.option].name.toLowerCase());
        case 'scaleMode':
          return this.getTitle(gridStore.scaleModes[this.$props.option].name.toLowerCase());
        default:
            return '';
      }
    },

    getSelectedSVG () {
      if (this.$props.type === 'view') {
        return 'eye_visible';
      } else {
        return 'check_on';
      }
    },

    getDeselectedSVG () {
      if (this.$props.type === 'view') {
        return 'eye_closed';
      } else {
        return 'check_off';
      }
    }

  },
  methods: {
    getTitle (str) {
      // TODO could be moved to a utilsStore

      return useSprinklerStore().getTitle(str);
    },
    handleOptionClick () {
      const gridStore = useGridStore();
      switch (this.$props.type) {
        case 'view': {
          let option = gridStore.viewOptions[this.$props.option];
          gridStore.viewOptions[this.$props.option] = !option;
          gridStore.storeSettings();
          break;
        }
        case 'theme': {
          gridStore.selectedThemeIndex = this.$props.option;
          gridStore.storeSettings();
          break;
        }
        case 'lineColor': {
          gridStore.selectedLineColorIndex = this.$props.option;
          gridStore.storeSettings();
          break;
        }
        case 'scaleMode': {
          gridStore.selectedScaleModeIndex = this.$props.option;
          gridStore.storeSettings();
          break;
        }
      }
    }
  }
};
</script>

<style scoped>
.option-text {
  font-size: 80%;
  width: 100%;
  padding-left: 10px;
}
.option-icon-holder {
  width: 18px;
  height: 18px;
}
.option-icon-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.option-visibility-holder {
  width: 22px;
}
</style>