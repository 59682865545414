<template>
  <ModalView />
  <router-view />
</template>

<script>
import { useIdentityStore } from '@/stores/IdentityStore';
import ModalView from '@/components/modal/ModalView.vue';

export default {
  name: 'App',
  data: () => ({
  }),
  components: {
    ModalView
  },
  setup () {
    useIdentityStore().init();
  },
};
</script>
