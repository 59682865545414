import { useIdentityStore } from '@/stores/IdentityStore';
import { useModalStore } from '@/stores/ModalStore';
import { createRouter, createWebHashHistory } from 'vue-router';
import ApiService from '@/services/ApiService';
import OverlapproView from '../views/OverlapproView.vue';

const routes = [
  {
    path: '/',
    name: 'overlap',
    component: OverlapproView
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach(async (to, from) => {
  const ident = useIdentityStore();
  // dynamic page title
  document.title = to.meta?.title ?? 'Nelson Overlap';
  if (process.env.NODE_ENV !== 'production') {
    console.log('To Path: ' + to.path);
    console.log('From Path: ' + from.path);
  }
  if (to.path !== '/callback') {
    // check to see if discovery document is stored
    if (!ident.discovery) {
      await ident.verifyDiscoveryDocument();
    }
    var token = ident.access_token();
    if (!token) {
      ident.ssoAuthenticate();
    }
    else {
      var bTokenActive = await ident.isTokenValid();
      if (!bTokenActive) {
        var refreshToken = ident.refresh_token();
        if (refreshToken) {
          await ident.ssoRefreshToken(refreshToken);
          token = ident.access_token();
          if (!token) {
            ident.ssoAuthenticate();
          }
        }
      }
      if (!ApiService.token) { ApiService.setAuthHeader(ident.access_token()); }
      var user = JSON.parse(localStorage.getItem('overlap_user'));
      if (!user) {
        ident.getTokenUser().then(() => {
          if (ident.User.properties.Overlap_HasAgreed === null) {
            useModalStore().showModal('tos');
          }
        });
      }
      else {
        ident.User = user;
        if (ident.User.properties.Overlap_HasAgreed === null) {
          useModalStore().showModal('tos');
        }
      }
    }
  }
});
router.onError(err => {
  print('<!-- router error: ' + err.message + ' -->');
});

export default router;
