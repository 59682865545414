<template>
  <div class="title-wrap">
    <img id="a-titleimage" :src="require('@/assets/Nelson_Logo_RGB_400x90.png')" class="logo" />
    <label id="a-titleLabel" class="overlap-title">{{ this.$title }}</label>
    <div id="a-registrationMark" class="registration-mark">&#174;</div>
    <div id="a-versionLabel" class="version">v {{ this.$version }}</div>
  </div>
</template>

<style scoped>
.logo {
  height: 30px;
  width: auto;
  padding: 6px;
}
.overlap-title {
  font-size: 30px;
  font-family: Helvetica, Arial, sans-serif;
  color: #00CCFF;
  font-weight: 400;
  vertical-align: bottom;
  margin-top: 5px;
  margin-left: 10px;
}
.registration-mark {
  color:#006ba6;
  margin-left:2px;
}
.title-wrap {
  display:flex;
  align-items:last center;
}
.version {
  color: #509e2f;
  margin-left:5px;
}
</style>