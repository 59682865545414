<template>
  <div class="input-row">
    <label id="f-placeholderLabel" class="input-label"></label>
    <label id="f-minLabel" class="min-label">MIN</label>
    <label id="f-maxLabel" class="max-label">MAX</label>
  </div>
  <div  class="input-row">
    <label id="f-applicationRateLabel" class="input-label">Application Rate</label>
    <input id="f-applicationRateMinInput" class="input-text" v-model="applicationRateMin">
    <input id="f-applicationRateMaxInput" class="input-text" v-model="applicationRateMax">
    <label id="f-applicationRateUnitsLabel" class="input-unit">{{ precipUnit }}</label>
  </div>
  <div class="input-row">
    <label id="f-flowRateLabel" class="input-label">Flow Rate</label>
    <input id="f-flowRateMinInput" class="input-text" v-model="flowRateMin">
    <input id="f-flowRateMaxInput" class="input-text" v-model="flowRateMax">
    <label id="f-flowRateUnitsLabel" class="input-unit">{{ flowUnit }}</label>
  </div>
</template>

<script>
import { useSettingsStore } from '@/stores/SettingsStore';
import { useSprinklerStore } from '@/stores/SprinklerStore';

export default {
  computed: {
    flowUnit () {
      return useSettingsStore().flowUnit;
    },
    precipUnit () {
      return useSettingsStore().precipUnit;
    },
    applicationRateMin: {
      get () {
        return useSprinklerStore().applicationRateMin;
      },
      set (value) {
        useSprinklerStore().applicationRateMin = value;
      }
    },
    applicationRateMax: {
      get () {
        return useSprinklerStore().applicationRateMax;
      },
      set (value) {
        useSprinklerStore().applicationRateMax = value;
      }
    },
    flowRateMax: {
      get () {
        return useSprinklerStore().flowRateMax;
      },
      set (value) {
        useSprinklerStore().flowRateMax = value;
      }
    },
    flowRateMin: {
      get () {
        return useSprinklerStore().flowRateMin;
      },
      set (value) {
        useSprinklerStore().flowRateMin = value;
      }
    },
  },
};
</script>

<style scoped>
.spacer {
  width: 170px;
}
.min-label {
  text-align: center;
  width: 50px;
}
.max-label {
  text-align: center;
  width: 50px;
}
.min-max-input {
  height: 20px;
  margin-left: 1%;
  margin-right: 1%;
  padding: 0px;
  width: 10%;
}
.input-label {
  padding-left: 20px;
  width: 100px;
}
</style>