<template>
  <div class="input-row">
    <div class="row-element">
      <label id="tvs-layoutLabel">Layout</label>
      <select id="tvs-layoutSelect" class="tree-spacing-input" v-model="treeLayout">
        <option :id="'tvs-' + option" v-for="option in treeLayoutOptions" :key="option">{{ option }}</option>
      </select>
    </div>
    <div class="row-element">
      <label id="tvs-pipeOriginLabel">Pipe Origin</label>
      <input id="tvs-pipeOriginInput" class="input-text" v-model="pipeOrigin">
      <label id="tvs-pipeOriginUnitLabel" class="input-unit">{{ lengthUnit }}</label>
    </div>
  </div>
  <div class="input-row">
    <div class="row-element">
      <label id="tvs-spacingLabel">Spacing</label>
      <input id="tvs-spacingInput" class="input-text" v-model="treeSpacing">
      <label id="tvs-spacingUnitLabel" class="input-unit">{{ lengthUnit }}</label>
    </div>
    <div class="row-element">
      <label id="tvs-firstSprDistanceLabel">1st Sprinkler distance from 1st tree/vine</label>
      <input id="tvs-firstSprDistanceInput" class="input-text" v-model="firstSprDistance">
      <label id="tvs-firstSprDistanceUnitLabel" class="input-unit">{{ lengthUnit }}</label>
    </div>
  </div>
  <div class="input-row">
    <div class="row-element">
      <label id="tvs-rowSpacingLabel">Row Spacing</label>
      <input id="tvs-rowSpacingInput" :disabled="isEquilateral" class="input-text" v-model="treeRowSpacing">
      <label id="tvs-" class="input-unit">{{ lengthUnit }}</label>
    </div>
    <div class="row-element">
      <label id="tvs-irrStripWidthLabel">Irrigated Strip Width</label>
      <input id="tvs-irrStripWidthInput" class="input-text" v-model="irrStripWidth">
      <label id="tvs-irrStripWidthUnitLabel" class="input-unit">{{ lengthUnit }}</label>
    </div>
  </div>
  <div class="input-row">
    <div class="row-element">
      <label id="tvs-rootDiameterLabel">Root Diameter</label>
      <input id="tvs-rootDiameterInput" class="input-text" v-model="treeDiameter">
      <label id="tvs-rootDiameterUnitLabel" class="input-unit">{{ lengthUnit }}</label>
    </div>
    <div class="row-element">
    </div>
  </div>
</template>

<script>
import { useSettingsStore } from '@/stores/SettingsStore';
import { useSprinklerStore } from '@/stores/SprinklerStore';

export default {
  computed: {
    lengthUnit () {
      return useSettingsStore().lengthUnit;
    },
    isEquilateral: {
      get () {
        return useSprinklerStore().isEquilateralTreeLayout;
      }
    },
    treeLayout: {
      get () {
        return useSprinklerStore().treeLayout;
      },
      set (value) {
        useSprinklerStore().treeLayout = value;
      }
    },
    treeLayoutOptions () {
      return useSprinklerStore().treeLayoutOptions;
    },
    pipeOrigin: {
      get () {
        return useSprinklerStore().pipeOrigin;
      },
      set (value) {
        useSprinklerStore().pipeOrigin = value;
      }
    },
    treeSpacing: {
      get () {
        return useSprinklerStore().treeSpacing;
      },
      set (value) {
        useSprinklerStore().treeSpacing = value;
      }
    },
    firstSprDistance: {
      get () {
        return useSprinklerStore().firstSprDistance;
      },
      set (value) {
        useSprinklerStore().firstSprDistance = value;
      }
    },
    treeRowSpacing: {
      get () {
        return useSprinklerStore().getTreeRowSpacing;
      },
      set (value) {
        useSprinklerStore().treeRowSpacing = value;
      }
    },
    irrStripWidth: {
      get () {
        return useSprinklerStore().irrStripWidth;
      },
      set (value) {
        useSprinklerStore().irrStripWidth = value;
      }
    },
    treeDiameter: {
      get () {
        return useSprinklerStore().treeDiameter;
      },
      set (value) {
        useSprinklerStore().treeDiameter = value;
      }
    },
  },
};
</script>

<style scoped>
label {
  margin-left: 10px;
  flex:1;
}
.input-label {
  margin-left: 20px;
  width: 120px;
  margin-right: 5px;
}
.checkbox {
  margin-bottom: 5px;
  margin-left: 50%;
}
.tree-spacing-input {
  height: 20px;
  margin-left: 1%;
  margin-right: 1%;
  padding: 0px;
  width: 100px;
  font-size: 80%;
}
</style>