<template>
  <div id="sg-gridContainer" class="grid-container">
    <div class="title">Precipitation Grid for: <span class="rec-number">{{ recNumber }}</span>
      <a href="/content/overlaphelp.pdf" target="_blank" class="button-plain">Help</a>
    </div>
    <div class="data-line"></div>
    <div style="display: flex; flex-direction: row;">
      <div class="wind-info-display" v-show="this.hasWind">
        <span id="windArrow">Wind Direction: </span>
        <img id="arrow" ref="arrow" :src="require('@/assets/png/right-arrow.png')" class="wind-arrow" />
        <span class="wind-speed-label">Wind Speed: </span>
        <span class="wind-speed-value">{{ windSpeed }} {{ speedUnit }}</span>
      </div>
    </div>
    <div class="grid">
      <div class="grid-display" ref="overlapGrid">
        <div class="overlay-grid-holder">
          <OverlapGrid :record="getRecord" />
        </div>
        <div class="precip-holder">
          <PrecipitationLegend />
        </div>
      </div>
    </div>
    <div class="grid-options">
      <GridOptions type="view" />
      <GridOptions type="theme" />
      <div>
        <GridOptions type="scaleMode" />
        <div class="input-label" v-show="scaleModeName == 'Fixed'">
          <label>Min Value:</label>
          <input id="sg-fixed-minValue" class="input-text" v-model.lazy="fixedModeMinimum">
        </div>
        <div class="input-label" v-show="scaleModeName == 'Fixed'">
          <label>Increment:</label>
          <input id="sg-fixed-increment" class="input-text" v-model.lazy="fixedModeIncrement">
        </div>
      </div>
      <GridOptions type="lineColor" />
    </div>
  </div>
</template>

<script>
import { useGridStore } from '@/stores/GridStore';
import { useSettingsStore } from '@/stores/SettingsStore';
import { useSprinklerStore } from '@/stores/SprinklerStore';
import GridOptions from '@/components/sprinkler_grid_components/GridOptions';
import OverlapGrid from '@/components/sprinkler_grid_components/OverlapGrid';
import PrecipitationLegend from '@/components/sprinkler_grid_components/PrecipitationLegend';

export default {
  components: {
    GridOptions,
    OverlapGrid,
    PrecipitationLegend,
  },
  mounted () {
    useSprinklerStore().setSprinklerGridPanel(this);
  },
  computed: {
    hasWind () {
      return useSprinklerStore().winds[0];
    },
    fixedModeMinimum: {
      get () {
        return useGridStore().fixedModeMin;
      },
      set (value) {
        useGridStore().setFixedModeMin(value);
      }
    },
    fixedModeIncrement: {
      get () {
        return useGridStore().fixedModeIncrement;
      },
      set (value) {
        useGridStore().setFixedModeIncrement(value);
      }
    },
    scaleModeName () {
      return useGridStore().selectedScaleMode.name;
    },
    recNumber () {
      const sprinklerStore = useSprinklerStore();
      if (sprinklerStore.selectedRecord == null) { return ''; }
      return sprinklerStore.selectedRecord.RecNum;
    },
    speedUnit () {
      return useSettingsStore().speedUnit;
    },
    pipeDirection () {
      return useSprinklerStore().pipeDirection;
    },
    windDirection () {
      return useSprinklerStore().windDirection;
    },
    windSpeed () {
      const sprinklerStore = useSprinklerStore();
      if (sprinklerStore.selectedRecord == null) { return 0; }
      return sprinklerStore.selectedRecord.WndSpd;
    },
    getRecord () {
      return useSprinklerStore().selectedRecord;
    }
  },
  watch: {
    pipeDirection () {
      this.updateWindArrow();
    },
    windDirection () {
      this.updateWindArrow();
    },
    hasWind () {
      this.updateWindArrow();
    }
  },
  methods: {
    updateWindArrow () {
      if (!this.hasWind) { return; }
      var arrow = document.getElementById('arrow');
      arrow.style.transform = 'rotate(' + (this.pipeDirection - this.windDirection + 180) + 'deg)';
    },
  }
};
</script>

<style scoped>
.button-plain {
  background-color: #e7e7e7;
  /* Green */
  border: none;
  color: black;
  padding: 5px 20px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
  float: right;
  margin-right: 5px;
}
.grid-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: 100%;
}
.grid-container-shrink {
  width: 0;
}
.grid {
  display: flex;
  flex-direction: row;
  flex: 3;
  overflow: hidden;
  max-width: 925px;
}
.grid-display {
  width: 100%;
  display: flex;
  overflow: hidden;
}
.grid-options {
  display: flex;
  width: 100%;
  max-width: 925px;
  justify-content: flex-start;
  align-items: flex-start;
}
.grid-option-stack {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.grid-options-right {
  display: flex;
}
.rec-number {
  color: #39b54a;
}
.overlay-grid-holder {
  flex: 5;
  display: flex;
  overflow: hidden;
}
.precip-holder {
  padding: 6px;
}
.wind-info-display {
  font-weight: bold;
  font-size: 90%;
  margin-left: 5px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}
.wind-arrow {
  height: 15px;
  width: auto;
  margin-left: 6px;
}
.wind-speed-label {
  margin-left: 80px;
}
.wind-speed-value {
  font-weight: 400 !important;
  margin-left: 5px;
}
.input-label {
  margin-top: 5px;
}
</style>