export const ConvertFtToMeters = (a) => {
  return parseFloat(a * 0.3048);
};
export const ConvertHaToAcre = (a) => {
  return parseFloat(a * 2.47);
};
export const ConvertM2ToFT2 = (a) => {
  return parseFloat(a * 10.7639104);
};
export const ConvertFT2ToM2 = (a) => {
  return parseFloat(a / 10.7639104);
};
export const ConvertM2ToAcre = (a) => {
  return parseFloat(a * 0.000247105);
};
export const ConvertFT2toAcres = (a) => {
  return parseFloat(a / 43560);
};
export const ConvertAcreToM2 = (a) => {
  return parseFloat(a / 0.000247105);
};
export const ConvertFT2toHectare = (a) => {
  return parseFloat(a / 107639.104);
};
export const ConvertM2ToHectare = (a) => {
  return parseFloat(a * 0.0001);
};
export const ConvertHectareToM2 = (a) => {
  return parseFloat(a / 0.0001);
};
export const ConvertAcreToHa = (a) => {
  return parseFloat(a / 2.47);
};
export const ConvertMetersToFt = (a) => {
  return parseFloat(a / 0.3048);
};
export const ConvertMetersToMM = (a) => {
  return parseFloat(a * 1000);
};
export const ConvertMetersToCM = (a) => {
  return parseFloat(a * 100);
};
export const ConvertMMToMeters = (a) => {
  return parseFloat(a / 1000);
};
export const ConvertMetersToIn = (a) => {
  return parseFloat(a / 0.0254);
};
export const ConvertInToMeters = (a) => {
  return parseFloat(a * 0.0254);
};
export const ConvertPAStoPSI = (a) => {
  return parseFloat(a * 0.000145038);
};
export const ConvertPSItokPa = (a) => {
  return parseFloat(a * 6.89475729);
};
export const ConvertPSItoPAS = (a) => {
  return parseFloat(a / 0.000145038);
};
export const ConvertPSItoBar = (a) => {
  return parseFloat(a / 14.503773773);
};
export const ConvertPAStoBAR = (a) => {
  return parseFloat(a * 0.00001);
};
export const ConvertBARtoPAS = (a) => {
  return parseFloat(a / 0.00001);
};
// 1 lpm per hectare = 0.106906637 (US gallons per minute) per acre
export const ConvertLPMHtoGPMA = (a) => {
  return parseFloat(a * 0.106906637);
};
export const ConvertGPMAtoLPMH = (a) => {
  return parseFloat(a /  0.10690663667434);
};
export const ConvertGPMAtoLPSH = (a) => {
  return parseFloat(a) * 0.15589927;
};
export const ConvertLPMHtoMMPD = (a) => {
  // mm/day
  return parseFloat(a * 0.144);
};
export const ConvertLPMHtoINPD = (a) => {
  // in/day
  return parseFloat(ConvertLPMHtoGPMA(a) * 0.05303);
};
export const ConvertMMPDtoLPMH = (a) => {
  // mm/day
  return parseFloat(a / 0.144);
};
export const ConvertINPDtoGPMA = (a) => {
  // in/day
  return parseFloat(a / 0.05303);
};
export const ConvertLPMtoGPM = (a) => {
  return parseFloat(a * 0.26417287472922);
};
export const ConvertGPMtoLPM = (a) => {
  return parseFloat(a / 0.26417287472922);
};
export const ConvertLPMtoGPH = (a) => {
  return parseFloat(a * 15.850323141489);
};
export const ConvertGPMtoGPH = (a) => {
  return parseFloat(a * 60);
};
export const ConvertGPHtoGPM = (a) => {
  return parseFloat(a) * 0.01667;
};
export const ConvertLPMtoLPS = (a) => {
  return parseFloat(a) / 60;
};
export const ConvertGPMtoLPS = (a) => {
  return parseFloat(a * 0.0630901964);
};
export const ConvertLPStoGPM = (a) => {
  return parseFloat(a) * 15.850323141489;
};
export const ConvertLPMtoLPH = (a) => {
  return parseFloat(a) * 60;
};
export const ConvertGPMtoLPH = (a) => {
  return parseFloat(a * 227.12470704);
};
export const ConvertLPHtoGPM = (a) => {
  return parseFloat(a) * 0.004403; 
}; 
export const ConvertLPStoLPM = (a) => {
  return parseFloat(a) * 60;
};
export const ConvertLPMHtoLPSH = (a) => {
  return parseFloat(a) / 60;
};
export const ConvertLPSHtoLPMH = (a) => {
  return parseFloat(a) * 60;
};
export const ConvertLPMtoM3H = (a) => {
  return parseFloat(a) * 0.06;
};
export const ConvertGPMtoM3H = (a) => {
  return parseFloat(a * 0.22712470704);
};
export const ConvertM3HtoGPM = (a) => {
  return parseFloat(a) * 4.4029; 
};
export const ConvertM3HtoLPM = (a) => {
  return parseFloat(a) / 0.06;
};
export const ConvertINtoMM = (a) => {
  return parseFloat(a) * 25.4;
};
export const ConvertINtoCM = (a) => {
  return parseFloat(a) * 2.54; 
};
export const ConvertMMtoIN = (a) => {
  return parseFloat(a) / 25.4;
};
export const ConvertMMHtoINH = (a) => {
  return parseFloat(a) / 25.4;
};
export const ConvertINHtoMMH = (a) => {
  return parseFloat(a) * 25.4;
};
export const ConvertMMtoCM = (a) => {
  return parseFloat(a) / 10;
};
export const ConvertKPHtoMPH = (a) => {
  return 1.609344 / parseFloat(a);
};
export const ConvertMPHtoKPH = (a) => {
  return 1.609344 * parseFloat(a);
};
export const FilterHeight = (height, unit) => {
  if (height == null) { return 0; }
  if (unit == 'mm') { return ConvertINtoMM(height); }
  if (unit == 'cm') { return ConvertINtoCM(height); }
  return height;
};
export const FilterLength = (length, unit) => {
  if (length == null) { return 0; }
  if (unit == 'm') { return ConvertFtToMeters(length); }
  return length;
};
export const FilterPressure = (pressure, unit) => {
  if (pressure == null) { return 0; }
  if (unit  == 'kPa') { return ConvertPSItokPa(pressure); }
  if (unit == 'bar') { return ConvertPSItoBar(pressure); }
  return pressure;
};
export const FilterArea = (area, unit) => {
  if (area == null) { return 0; }
  if (unit == 'm2') { return ConvertFT2ToM2(area); }
  if (unit == 'acres') { return ConvertFT2toAcres(area); }
  if (unit == 'ha') { return ConvertFT2toHectare(area); }
  return area;
};
export const FilterFlow = (flow, unit) => {
  if (flow == null) { return 0; }
  if (unit == 'gal/hr') { return ConvertGPMtoGPH(flow); }
  if (unit == 'liters/sec') { return ConvertGPMtoLPS(flow); }
  if (unit == 'liters/min') { return ConvertGPMtoLPM(flow); }
  if (unit == 'liters/hr') { return ConvertGPMtoLPH(flow); }
  if (unit == 'm3/h') { return ConvertGPMtoM3H(flow); }
  return flow;
};
export const FilterRate = (rate, unit) => {
  if (rate == null) { return 0; }
  if (unit == 'liters/min/ha') { return ConvertGPMAtoLPMH(rate); }
  if (unit == 'liters/sec/ha') { return ConvertGPMAtoLPSH(rate); }
  return rate;
};
export const FilterPrecip = (precip, unit) => {
  if (precip == null) { return 0; }
  if (unit =='mm/hr') { return ConvertINHtoMMH(precip, unit); }
  return precip;
};
export const FilterSpeed = (speed, unit) => {
  if (speed == null) { return 0; }
  if (unit == 'kph') { return ConvertMPHtoKPH(speed); }
  return speed;
};
export const FilterPerArea = (perArea, unit) => {
  if (perArea == null) { return 0; }
  if (unit == 'acres') { return 1 / ConvertM2ToAcre(1 / perArea); }
  if (unit == 'hectares') { return 1 / ConvertM2ToHectare(1 / perArea); }
  return perArea;
};
export const UnfilterLength = (length, unit) => {
  if (length == null) { return 0; }
  if (unit == 'ft') { return ConvertFtToMeters(length); }
  return length;
};
export const UnfilterFlow = (flow, unit) => {
  if (flow == null) { return 0; }
  if (unit == 'gal/min') { return ConvertGPMtoLPM(flow); }
  if (unit == 'liters/sec') { return flow * 60; }
  if (unit == 'm3/h') { return ConvertM3HtoLPM(flow); }
  return flow;
};
export const UnfilterArea = (area, unit) => {
  if (area == null) { return 0; }
  if (unit == 'acres') { return ConvertAcreToM2(area); }
  if (unit == 'hectares') { return ConvertHectareToM2(area); }
  return area;
};
// Flow Conversions
export const FlowFromRate = (rate, length, arc, runLength) => {
  if (runLength == 0 && length == 0) { return 0; }
  // rate = export const ConvertLPMtoGPM(rate);
  if (runLength == 0 || runLength == null) {
    return rate * Math.PI * (Math.pow(length, 2) / 10000) * (arc / 360);
  } else {
    return rate * length * (runLength / 10000);
  }
};
export const FlowFromRadius = (L, r, Qtot, AppRate) => {
  // Machine Length, EG Radius, TotalFlow, AppRate
  if (AppRate == null) {
    // Caclulate from Qtot
    AppRate = (Qtot * 10000) / (Math.pow(L + r, 2) * Math.PI); // lpm/ha
  }
  var Qgun = (AppRate * Math.PI * (Math.pow(L + r, 2) - Math.pow(L, 2))) / 10000; // lpm
  return Qgun;
};
export const RadiusFromFlow = (L, Qgun, Qtot, AppRate) => {
  // Machine Length, EG Flow, TotalFlow, AppRate
  if (AppRate == null) {
    // Calculate from flow
    AppRate = ((Qtot - Qgun) * 10000) / (Math.PI * Math.pow(L, 2)); // lpm/ha
  } else if (Qtot == null) {
    // Calculate from Rate
    Qtot = (AppRate * Math.PI * Math.pow(L, 2)) / 10000 + Qgun;
  }
  var radiusAdjusted = Math.sqrt((Qtot * 10000) / (AppRate * Math.PI)) - L;
  return radiusAdjusted;
};
export const translateImportLength = (len, units) => {
  switch (units) {
    case 'ft':
      return ConvertFtToMeters(len);
    case 'm':
      return parseFloat(len);
  }
};
export const translateImportPressure = (press, units) => {
  switch (units) {
    case 'psi':
      return ConvertPSItoPAS(press);
    case 'bar':
      return ConvertBARtoPAS(press);
  }
};
export const translateImportFlow = (flow, units) => {
  switch (units) {
    case 'gpm':
      return ConvertGPMtoLPM(flow);
    case 'lpm':
      return parseFloat(flow);
    case 'm�/hr':
      return ConvertM3HtoLPM(flow);
    case 'm3/hr':
      return ConvertM3HtoLPM(flow);
  }
};
export const translateImportDia = (dia, units) => {
  switch (units) {
    case 'in':
      return ConvertInToMeters(dia);
    case 'cm':
      return parseFloat(dia) * 0.01; // cm to m
    case 'mm':
      return ConvertMMToMeters(dia);
  }
};