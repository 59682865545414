<template>
  <WindOptions></WindOptions>
  <div id="si-sprinklerInputRow" ref="sprinklerInputRow" class="sprinkler-input-row">
    <div class="column">
      <div class="column-title">Product</div>
      <div class="column-choices">
        <div
          class="choice"
          :class="selectedSprinklerTypes.includes(sprinkler) ? 'selected-choice' : ''"
          :id="'si-productOption-' + formatOption(sprinkler)"
          v-for="sprinkler in sprinklerTypes"
          :key="sprinkler"
          @click="(e) => handleSprinkler(sprinkler, e)"
        >
          {{ sprinkler }}
        </div>
      </div>
    </div>
    <div class="column">
      <div class="column-title" v-if="selectedSprinklerTypes.length > 0">Model</div>
      <div class="column-choices">
        <div
          class="choice"
          :class="selectedModelTypes.includes(model) ? 'selected-choice' : ''"
          :id="'si-modelOption-' + formatOption(model)"
          ref="sprinklerModel"
          v-for="model in modelTypes" 
          :key="model"
          @click="(e) => handleModel(model, e)"
        >
          {{ model }}
        </div>
      </div>
    </div>
    <div class="column">
      <div class="column-title" v-if="selectedModelTypes.length > 0">Trajectory</div>
      <div class="column-choices">
        <div
          class="choice"
          :class="selectedTrajectoryTypes.includes(trajectory) ? 'selected-choice' : ''"
          :id="'si-trajectoryOption-' + formatOption(trajectory)"
          v-for="trajectory in trajectoryTypes"
          :key="trajectory"
          @click="(e) => handleTrajectory(trajectory, e)"
        >
          {{ trajectory }}
        </div>
      </div>
    </div>
    <div class="column">
      <div class="column-title" v-if="selectedTrajectoryTypes.length > 0">Nozzle</div>
      <div class="column-choices">
        <div
          class="choice"
          :class="selectedNozzleTypes.includes(nozzle) ? 'selected-choice' : ''"
          :id="'si-nozzleOption-' + formatOption(nozzle)"
          v-for="nozzle in nozzleTypes"
          :key="nozzle"
          @click="(e) => handleNozzle(nozzle, e)"
        >
          {{ nozzle }}
        </div>
      </div>
    </div>
    <div class="column">
      <div class="column-title" v-if="selectedNozzleTypes.length > 0">Pressure ({{ pressureUnit }})</div>
      <div class="column-choices">
        <div
          class="choice"
          :class="selectedPressureTypes.includes(pressure) ? 'selected-choice' : ''"
          :id="'si-pressureOption-' + formatOption(filterPressure(pressure))"
          v-for="pressure in pressureTypes"
          :key="pressure"
          @click="(e) => handlePressure(pressure, e)"
        >
          {{ filterPressure(pressure) }}
        </div>
      </div>
    </div>
    <div class="column">
      <div class="column-title" v-if="selectedPressureTypes.length > 0">Riser ({{ heightUnit }})</div>
      <div class="column-choices">
        <div
          class="choice"
          :class="selectedRiserTypes.includes(riser) ? 'selected-choice' : ''"
          :id="'si-riserOption-' + formatOption(filterHeight(riser))"
          v-for="riser in riserTypes"
          :key="riser"
          @click="(e) => handleRiser(riser, e)"
        >
          {{ filterHeight(riser) }}
        </div>
      </div>
    </div>
    <div class="column">
      <div class="column-title" v-if="selectedRiserTypes.length > 0">Inverted</div>
      <div class="column-choices">
        <div
          class="choice"
          :class="selectedOrientationTypes.includes(orientation) ? 'selected-choice' : ''"
          :id="'si-orientationOption-' + formatOption(orientation)"
          v-for="orientation in orientationTypes"
          :key="orientation ? 1 : 2"
          @click="(e) => handleOrientation(orientation, e)"
        >
          {{ orientation }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useSettingsStore } from '@/stores/SettingsStore';
import { useSprinklerStore } from '@/stores/SprinklerStore';
import * as UnitConversion from '@/services/UnitConversionService.js';
import WindOptions from '@/components/data_input_components/sprinkler_input_components/WindOptions';

export default {
  components: { WindOptions },
  created () {
    useSprinklerStore().getMenu();
  },
  updated () {
    this.checkScroll();
  },
  computed: {
    sprinklers () {
      return useSprinklerStore().sprinklers;
    },
    sprinklerTypes () {
      var temp = this.sprinklers;
      if (temp == null) { return []; }
      temp = [...new Set(temp.map((each) => { return each.SprinklerType; }))];
      return temp.sort((a, b) => { return a.localeCompare(b); });
    },
    selectedSprinklerTypes () {
      return useSprinklerStore().selectedSprinklers.map((each) => { return each.SprinklerType; });
    },
    models () {
      return useSprinklerStore().selectedSprinklers.map((each) => { return each.Models; }).flat();
    },
    modelTypes () {
      var temp = this.models;
      if (temp == null) { return []; }
      temp = [...new Set(temp.map((each) => { return each.Model; }))];
      return temp.sort((a, b) => { return a.localeCompare(b); });
    },
    selectedModelTypes () {
      return useSprinklerStore().selectedModels.map((each) => { return each.Model; });
    },
    trajectories () {
      return useSprinklerStore().selectedModels.map((each) => { return each.Trajectories; }).flat();
    },
    trajectoryTypes () {
      var temp = this.trajectories;
      if (temp == null) { return []; }
      temp = [...new Set(temp.map((each) => { return each.Trajectory; }))];
      return temp.sort((a, b) => { return parseFloat(a) > parseFloat(b) ? 1 : -1; });
    },
    selectedTrajectoryTypes () {
      return useSprinklerStore().selectedTrajectories.map((each) => { return each.Trajectory; });
    },
    nozzles () {
      return useSprinklerStore().selectedTrajectories.map((each) => { return each.Nozzles; }).flat();
    },
    nozzleTypes () {
      var temp = this.nozzles;
      if (temp == null) { return []; }
      temp = [...new Set(temp.map((each) => { return each.Nozzle; }))];
      return temp.sort((a, b) => { return a.localeCompare(b); });
    },
    selectedNozzleTypes () {
      return useSprinklerStore().selectedNozzles.map((each) => { return each.Nozzle; });
    },
    pressures () {
      return useSprinklerStore().selectedNozzles.map((each) => { return each.Pressures; }).flat();
    },
    pressureTypes () {
      var temp = this.pressures;
      if (temp == null) { return []; }
      temp = [...new Set(temp.map((each) => { return each.Pressure; }))];
      return temp.sort((a, b) => { return a.localeCompare(b); });
    },
    selectedPressureTypes () {
      return useSprinklerStore().selectedPressures.map((each) => { return each.Pressure; });
    },
    risers () {
      return useSprinklerStore().selectedPressures.map((each) => { return each.Risers; }).flat();
    },
    riserTypes () {
      var temp = this.risers;
      if (temp == null) { return []; }
      temp = [...new Set(temp.map((each) => { return each.Riser; }))];
      return temp.sort((a, b) => { return a.localeCompare(b); });
    },
    selectedRiserTypes () {
      return useSprinklerStore().selectedRisers.map((each) => { return each.Riser; });
    },
    orientations () {
      return useSprinklerStore().selectedRisers.map((each) => { return each.Orientations; }).flat();
    },
    orientationTypes () {
      var temp = this.orientations;
      if (temp == null) { return []; }
      temp = [...new Set(temp)];
      return temp.sort((a, b) => { return a.localeCompare(b); });
    },
    selectedOrientationTypes () {
      return useSprinklerStore().selectedOrientations;
    },
    pressureUnit () {
      return useSettingsStore().pressureUnit;
    },
    heightUnit () {
      return useSettingsStore().heightUnit;
    }
  },
  methods: {
    formatOption (option) {
      return option.replace(/\s|\.|\//g, '-').replace(/#/g, '');
    },
    filterHeight (height) {
      return (UnitConversion.FilterHeight(parseFloat(height), this.heightUnit)).toFixed(3);
    },
    filterPressure (pressure) {
      return (UnitConversion.FilterPressure(parseFloat(pressure), this.pressureUnit)).toFixed(3);
    },
    checkScroll () {
      let w = this.$refs.sprinklerInputRow.scrollWidth;
      this.$refs.sprinklerInputRow.scrollTo(w, 0);
    },
    handleSprinkler (choice, e) {
      const sprinklerStore = useSprinklerStore();
      if (!e.ctrlKey) { sprinklerStore.selectedSprinklers = []; }
      if (this.selectedSprinklerTypes.includes(choice)) {
        sprinklerStore.selectedSprinklers
          = sprinklerStore.selectedSprinklers.filter((each) => { return each.SprinklerType != choice; });
      } else {
        this.sprinklers.forEach((each) => {
          if (each.SprinklerType == choice) {
            sprinklerStore.selectedSprinklers.push(each);
          }
        });
      }
      sprinklerStore.selectedModels = [];
      sprinklerStore.selectedTrajectories = [];
      sprinklerStore.selectedNozzles = [];
      sprinklerStore.selectedPressures = [];
      sprinklerStore.selectedRisers = [];
      sprinklerStore.selectedOrientations = [];
      if (this.modelTypes.length == 1) {
        this.handleModel(this.models[0].Model, e);
      }
    },
    handleModel (choice, e) {
      const sprinklerStore = useSprinklerStore();
      if (!e.ctrlKey) { sprinklerStore.selectedModels = []; }
      if (this.selectedModelTypes.includes(choice)) {
        sprinklerStore.selectedModels
          = sprinklerStore.selectedModels.filter((each) => { return each.Model != choice; });
      } else {
        this.models.forEach((each) => {
          if (each.Model == choice) {
            sprinklerStore.selectedModels.push(each);
          }
        });
      }
      sprinklerStore.selectedTrajectories = [];
      sprinklerStore.selectedNozzles = [];
      sprinklerStore.selectedPressures = [];
      sprinklerStore.selectedRisers = [];
      sprinklerStore.selectedOrientations = [];
      if (this.trajectoryTypes.length == 1) {
        this.handleTrajectory(this.trajectories[0].Trajectory, e);
      }
    },
    handleTrajectory (choice, e) {
      const sprinklerStore = useSprinklerStore();
      if (!e.ctrlKey) { sprinklerStore.selectedTrajectories = []; }
      if (this.selectedTrajectoryTypes.includes(choice)) {
        sprinklerStore.selectedTrajectories
          = sprinklerStore.selectedTrajectories.filter((each) => { return each.Trajectory != choice; });
      } else {
        this.trajectories.forEach((each) => {
          if (each.Trajectory == choice) {
            sprinklerStore.selectedTrajectories.push(each);
          }
        });
      }
      sprinklerStore.selectedNozzles = [];
      sprinklerStore.selectedPressures = [];
      sprinklerStore.selectedRisers = [];
      sprinklerStore.selectedOrientations = [];
      if (this.nozzleTypes.length == 1) {
        this.handleNozzle(this.nozzles[0].Nozzle, e);
      }
    },
    handleNozzle (choice, e) {
      const sprinklerStore = useSprinklerStore();
      if (!e.ctrlKey) { sprinklerStore.selectedNozzles = []; }
      if (this.selectedNozzleTypes.includes(choice)) {
        sprinklerStore.selectedNozzles
          = sprinklerStore.selectedNozzles.filter((each) => { return each.Nozzle != choice; });
      } else {
        this.nozzles.forEach((each) => {
          if (each.Nozzle == choice) {
            sprinklerStore.selectedNozzles.push(each);
          }
        });
      }
      sprinklerStore.selectedPressures = [];
      sprinklerStore.selectedRisers = [];
      sprinklerStore.selectedOrientations = [];
      if (this.pressureTypes.length == 1) {
        this.handlePressure(this.pressures[0].Pressure, e);
      }
    },
    handlePressure (choice, e) {
      const sprinklerStore = useSprinklerStore();
      if (!e.ctrlKey) { sprinklerStore.selectedPressures = []; }
      if (this.selectedPressureTypes.includes(choice)) {
        sprinklerStore.selectedPressures
          = sprinklerStore.selectedPressures.filter((each) => { return each.Pressure != choice; });
      } else {
        this.pressures.forEach((each) => {
          if (each.Pressure == choice) {
            sprinklerStore.selectedPressures.push(each);
          }
        });
      }
      sprinklerStore.selectedRisers = [];
      sprinklerStore.selectedOrientations = [];
      if (this.riserTypes.length == 1) {
        this.handleRiser(this.risers[0].Riser, e);
      }
    },
    handleRiser (choice, e) {
      const sprinklerStore = useSprinklerStore();
      if (!e.ctrlKey) { sprinklerStore.selectedRisers = []; }
      if (this.selectedRiserTypes.includes(choice)) {
        sprinklerStore.selectedRisers
          = sprinklerStore.selectedRisers.filter((each) => { return each.Riser != choice; });
      } else {
        this.risers.forEach((each) => {
          if (each.Riser == choice) {
            sprinklerStore.selectedRisers.push(each);
          }
        });
      }
      sprinklerStore.selectedOrientations = [];
      if (this.orientationTypes.length == 1) {
        this.handleOrientation(this.orientations[0], e);
      }
    },
    handleOrientation (choice, e) {
      const sprinklerStore = useSprinklerStore();
      if (!e.ctrlKey) { sprinklerStore.selectedOrientations = []; }
      if (this.selectedOrientationTypes.includes(choice)) {
        sprinklerStore.selectedOrientations
          = sprinklerStore.selectedOrientations.filter((each) => { return each != choice; });
      } else {
        this.orientations.forEach((each) => {
          if (each == choice) {
            sprinklerStore.selectedOrientations.push(each);
          }
        });
      }
    },
  }
};
</script>

<style scoped>
.selected-choice {
  background-color: whitesmoke;
}
.checkbox-row {
  margin-left: 10px;
}
.sprinkler-input-row {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  margin-left: 10px;
}
.column-title {
  font-size: 80%;
  font-weight: 600;
}
.column {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.column-choices {
  margin-right: 6px;
  overflow-y: auto;
  height: 125px;
  display: flex;
  flex-direction: column;
}
.choice {
  max-height: 35px;
  min-width: 80px;
  padding: 2.8px;
  font-size: 80%;
  white-space: nowrap;
  display: flex;
  align-items: flex-end;
  border-bottom: .5px solid #d2d2d2;
}
</style>
