import { defineStore } from 'pinia';
import { useIdentityStore } from '@/stores/IdentityStore';
import * as UnitConversion from '@/services/UnitConversionService.js';

export const useSettingsStore = defineStore('settings', {
	state () {
		return {
      heightIndex: 0,
			lengthIndex: 0,
			pressureIndex: 0,
      areaIndex: 0,
			flowIndex: 0,
			rateIndex: 0,
      precipIndex: 0,
      speedIndex: 0,
			windowSplit: 65
		};
	},
	getters: {
    heightUnit () {
			return this.heightOptions[this.heightIndex];
		},
		lengthUnit () {
			return this.lengthOptions[this.lengthIndex];
		},
		pressureUnit () {
			return this.pressureOptions[this.pressureIndex];
		},
    areaUnit () {
			return this.areaOptions[this.areaIndex];
		},
		flowUnit () {
			return this.flowOptions[this.flowIndex];
		},
		rateUnit () {
			return this.rateOptions[this.rateIndex];
		},
    precipUnit () {
			return this.precipOptions[this.precipIndex];
		},
    speedUnit () {
			return this.speedOptions[this.speedIndex];
		},
    heightOptions () {
			return ['inches', 'mm', 'cm'];
		},
		lengthOptions () {
			return ['ft', 'm'];
		},
		pressureOptions () {
			return ['psi', 'kPa', 'bar'];
		},
    areaOptions () {
			return ['ft2', 'm2', 'acres', 'ha'];
		},
		flowOptions () {
			return ['gal/min', 'gal/hr', 'liters/sec', 'liters/min', 'liters/hr', 'm3/h'];
		},
		rateOptions () {
			return ['gal/min/acre', 'liters/sec/ha', 'liters/min/ha'];
		},
    precipOptions () {
			return ['in/hr', 'mm/hr'];
		},
    speedOptions () {
			return ['mph', 'kph'];
		},
		units () {
			return this.lengthIndex + ':' + this.pressureIndex + ':' + this.flowIndex + ':' + this.rateIndex + ':' + this.areaIndex + ':' + this.heightIndex + ':' + this.precipIndex + ':' + this.speedIndex;
		},
		getWindowSplit () {
			return this.windowSplit;
		}
	},
	actions: {
    setHeightUnit (value) {
			this.heightIndex = this.heightOptions.indexOf(value);
			useIdentityStore().setUserProperty({ Units: this.units });
		},
		setLengthUnit (value) {
			this.lengthIndex = this.lengthOptions.indexOf(value);
			useIdentityStore().setUserProperty({ Units: this.units });
		},
		setPressureUnit (value) {
			this.pressureIndex = this.pressureOptions.indexOf(value);
			useIdentityStore().setUserProperty({ Units: this.units });
		},
    setAreaUnit (value) {
			this.areaIndex = this.areaOptions.indexOf(value);
			useIdentityStore().setUserProperty({ Units: this.units });
		},
		setFlowUnit (value) {
			this.flowIndex = this.flowOptions.indexOf(value);
			useIdentityStore().setUserProperty({ Units: this.units });
		},
		setRateUnit (value) {
			this.rateIndex = this.rateOptions.indexOf(value);
			useIdentityStore().setUserProperty({ Units: this.units });
		},
		setPrecipUnit (value) {
			this.precipIndex = this.precipOptions.indexOf(value);
			useIdentityStore().setUserProperty({ Units: this.units });
		},
    setSpeedUnit (value) {
			this.speedIndex = this.speedOptions.indexOf(value);
			useIdentityStore().setUserProperty({ Units: this.units });
		},
		restoreUnits () {
			const identityStore = useIdentityStore();
			var storedUnits = identityStore.User.properties.Overlap_Units;
			if (storedUnits == null) {
				identityStore.setUserProperty({ Units: this.units });
				return;
			}
			var units = storedUnits.split(':');
			this.lengthIndex = parseInt(units[0]);
			this.pressureIndex = parseInt(units[1]);
			this.flowIndex = parseInt(units[2]);
			this.rateIndex = parseInt(units[3]);
			this.areaIndex = parseInt(units[4]);
      this.heightIndex = parseInt(units[5]);
      this.precipIndex = parseInt(units[6]);
      this.speedIndex = parseInt(units[7]);
		},
		setWindowSplit (val) {
			this.windowSplit = val;
			let dgh = document.getElementById('dataGridHolder');
			dgh.style.height = this.windowSplit + 'px';
		},
		valueFor (type, value) {
      if (typeof value === 'string' || value instanceof String) {
        if (type == 'spacing') {
          var splitSpacing = value.split('x');
          return this.convertValueFor(type, parseFloat(splitSpacing[0])) + 'x' + this.convertValueFor(type, parseFloat(splitSpacing[1]));
        }
        if (type == 'rate') {
          return this.convertValueFor(type, parseFloat(value));
        }
        return value;
      }
      return this.convertValueFor(type, value);
    },
    convertValueFor (type, value) {
      switch (type) {
        case 'height':
          return (UnitConversion.FilterHeight(value, this.heightUnit)).toFixed();
        case 'spacing':
        case 'length':
          return (UnitConversion.FilterLength(value, this.lengthUnit)).toFixed(2);
        case 'pressure':
          return (UnitConversion.FilterPressure(value, this.pressureUnit)).toFixed();
        case 'area':
          return (UnitConversion.FilterArea(value, this.areaUnit)).toFixed(2);
        case 'flow':
          return (UnitConversion.FilterFlow(value, this.flowUnit)).toFixed(2);
        case 'rate':
          return (UnitConversion.FilterRate(value, this.rateUnit)).toFixed(2);
        case 'precip':
          return (UnitConversion.FilterPrecip(value, this.precipUnit)).toFixed(2);
        case 'speed':
          return (UnitConversion.FilterSpeed(value, this.speedUnit)).toFixed(2);
        default:
          return value;
      }
    },
    unitFor (type) {
      switch (type) {
        case 'height':
          return this.heightUnit;
        case 'length':
          return this.lengthUnit;
        case 'pressure':
          return this.pressureUnit;
        case 'area':
          return this.areaUnit;
        case 'flow':
          return this.flowUnit;
        case 'rate':
          return this.rateUnit;
        case 'precip':
          return this.precipUnit;
        case 'speed':
          return this.speedUnit;
        default:
          return '';
      }
    }
	},
});
