<template>
  <div class="row">
    <h3>Change Password</h3>
  </div>
  <div class="row">
    <div>New Password:</div>
    <input type="password" class="password-input" :value="this.newPassword"
      @blur="this.newPassword = $event.target.value" />
  </div>
  <div class="row">
    <div>Confirm Password:</div>
    <input type="password" class="password-input" :value="this.confirmPassword"
      @blur="this.confirmPassword = $event.target.value" />
  </div>
  <div>
    <button @click="this.changePassword" class="save-button">Save</button>
  </div>
</template>

<script>
import { useIdentityStore } from '@/stores/IdentityStore.js';

export default {
  data: () => ({
    newPassword: '',
    confirmPassword: '',
    redeemToken: '',
  }),
  methods: {
    changePassword () {
      if (this.newPassword == '' || this.confirmPassword == '') { return; }
      if (this.newPassword != this.confirmPassword) { return; }
      useIdentityStore().setUserPassword(this.newPassword);
    },
  }
};
</script>

<style scoped>
.row {
  display: flex;
  flex-direction: column;
}
.password-input {
  width: 97%;
  min-height: 35px;
  margin-top: 8px;
  border-radius: 20px;
  padding-left: 10px;
  font-size: .8em;
  border: 1px solid lightgray;
}
.save-button {
  margin-top: 15px;
  height: 50px;
  width: 80px;
  background-color: var(--nelson-green);
}
</style>