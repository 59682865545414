<template>
  <div class="nui-button-holder" :class="this.$props.isSelected ? 'selected' : ''" @click="handleButtonClick">
    <div :style="this.showMarker('top')"></div>
    <div class="nui-button-content">
      <div class="icon_label_holder" v-if="this.$props.displayType === 'icon'">
        <div class="nui-spacer"></div>
        <div class="nui-label-holder" v-if="this.$props.obj.icon === 'tune'|| this.$props.obj.icon === 'lock'">
          <img class="icon" :src="require('@/assets/svg/SVG/' + this.$props.obj.icon + '.svg')" />
        </div>
        <div class="nui-label-holder" v-if="this.$props.obj.icon != 'tune' && this.$props.obj.icon != 'lock'">
          <div class="nui-button-icons">
            <img class="icon" :src="require('@/assets/svg/SVG/' + this.$props.obj.icon + '.svg')" />
          </div>
          <div class="text">{{ this.$props.obj.label }}</div>
        </div>
        <div class="nui-spacer r" v-if="this.$props.obj.icon != 'tune' && this.$props.obj.icon != 'lock'">
          <img class="append-icon" v-if="this.$props.appendIcon"
            :src="require('@/assets/svg/SVG/' + this.$props.appendIcon + '.svg')" />
        </div>
      </div>
      <div class="no_icon_label_holder" v-if="this.$props.displayType === 'no-icon'">
        <div :class="this.$props.class" class="nui-swatch"></div>
        <div class="text">{{ this.$props.obj.label }}</div>
      <div class="nui-spacer r">
        <img class="append-icon" v-if="this.$props.appendIcon"
          :src="require('@/assets/svg/SVG/' + this.$props.appendIcon + '.svg')" />
      </div>
      </div>
    </div>
    <div :style="this.showMarker('bottom')"></div>
  </div>
</template>

<script>
export default {
  name: 'NUIButton',
  props: {
    obj: {
      type: Object,
      required: true
    },
    displayType: {
      type: String,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: false
    },
  },
  methods: {
    handleButtonClick (e) {
      e.preventDefault();
      if (this.$props.isDisable !== true) {
        this.$props.obj.func(this.$props.obj.value);
      }
    },
    showMarker (orientation) {
      if (this.$props.obj.orientation === orientation) {
        let opacity = .4;
        if (this.$props.isSelected) { opacity = 1; }

        // this is a hack to avoid setting the opacity as it changes the z-index which ends up showing up on top of the overlay when selecting programs/values
        let bg_hex = getComputedStyle(document.documentElement).getPropertyValue(this.$props.obj.marker.toString().substring(4, this.$props.obj.marker.length - 1));
        return {
          'width': '100%',
          'height': '5px',/*
          'opacity': opacity,*/
          'background-color': this.hexToRGB(bg_hex, opacity)
        };
      } else {
        return {};
      }
    },
    hexToRGB (hex, alpha) {
      let r = parseInt(hex.slice(1, 3), 16),
          g = parseInt(hex.slice(3, 5), 16),
          b = parseInt(hex.slice(5, 7), 16);
      if (alpha) {
        return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
      } else {
        return 'rgb(' + r + ', ' + g + ', ' + b + ')';
      }
    }
  }
};
</script>

<style scoped>
.test {
  background-color: orange;
}
.nui-button-holder {
  height: 100%;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  display: flex;
  flex: 1;
  user-select: none;
}
.nui-button-holder:hover {
  background-color: #f2f2f2;
}
.marker {
  width: 100%;
  height: 5px;
}
.icon {
  width: 22px;
  margin-bottom: 4px;
}
.append-icon {
  width: 15px;
}
.text {
  color: black;
  font-size: .8em;
  white-space: nowrap;
}
.no_icon_label_holder {
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.icon_label_holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}
.selected {
  background-color: #f6f6f6;
}
.nui-button-icons {
  display: flex;
  flex-direction: row;
}
.nui-button-hide {
  position: absolute;
  right: 5px;
  top: 7px;
}
.hide-abs-wrapper {
  position: absolute;
}
.nui-button-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nui-swatch {
  margin-left: 8px;
}
.nui-spacer {
  width: 30px;
  height: 100%;
}
.r {
  margin-left: auto;
}
.nui-label-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}
</style>