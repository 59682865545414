<template>
  <div class="print-sheet-title test-data">
    Test Data
  </div>
  <div class="test-data-holder">
    <div class="test-data-left">
      Tests are performed in accordance with ASABE S398.1, with noted exceptions, and distribution characterization is in accordance with ISO 15886-3, with noted exceptions; contact Nelson Irrigation for details. Your results may differ from the test data results obtained by Nelson Irrigation Technologies. Any test data provided is subject to the Disclaimers and Limitation of Liability set out in Section 8 and 9 of the Terms of Use.
    </div>
    <div class="test-data-right">
      These results are based on sprinkler tests in still air or low wind conditions and assume precise spacing, vertical sprinkler alignment, unchanging pressure, and uniform riser heights. Field factors that also affect uniformity of water application are: wind speed and direction, stream obstruction, sprinkler struts, slope, and pressure variation within an irrigated zone. Crop uniformity can be affected by water application uniformity, soil type and texture, root system size, irrigation scheduling, and crop management.
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrintTestData'
};
</script>

<style scoped>
.test-data {
  padding-left: 15px;
}
.test-data-holder {
  font-size: 58%;
  display: flex;
}
.test-data-left {
  flex: 1;
  padding-left: 15px;
  padding-right: 10px;
}
.test-data-right {
  padding-left: 10px;
  padding-right: 15px;
  flex: 1;
}
</style>