<template>
  <div class="checkbox-row">
    <div class="row-element">
      <input id="wo-noWindCheckbox" type="checkbox" v-model="noWind">
      <label id="wo-noWindLabel" for="noWindCheckbox">No Wind</label>
    </div>
    <div class="row-element">
      <input id="wo-windCheckbox" type="checkbox" v-model="wind">
      <label id="wo-windLabel" for="windCheckbox">Wind</label>
    </div>
    <div class="row-element re-min">
    </div>
  </div>
  <div class="wind-options" v-show="wind">
    <label id="wo-pipeDirectionLabel">Pipe Direction</label>
    <input id="wo-pipeDirectionInput" class="input-text" v-model="pipeDirection">
    <label id="wo-windDirectionLabel">Wind Direction</label>
    <input id="wo-windDirectionInput" class="input-text" v-model="windDirection">
  </div>
</template>

<script>
import { useSprinklerStore } from '@/stores/SprinklerStore';
import { nextTick } from 'vue';

export default {
  name: 'WindOptions',
  computed: {
    pipeDirection: {
      get () {
        return useSprinklerStore().pipeDirection;
      },
      set (value) {
        return useSprinklerStore().pipeDirection = value;
      }
    },
    windDirection: {
      get () {
        return useSprinklerStore().windDirection;
      },
      set (value) {
        return useSprinklerStore().windDirection = value;
      }
    },
    wind : {
      get () {
        return useSprinklerStore().winds[0];
      },
      set (value) {
        const sprinklerStore = useSprinklerStore();
        const winds = sprinklerStore.winds;
        winds[0] = value;
        if (winds[0] === false && winds[1] === false) {
          nextTick(() => {
            winds[0] = true;
          });
        } else {
          sprinklerStore.resetSprinklerSelection();
        }
      }
    },
    noWind : {
      get () {
        return useSprinklerStore().winds[1];
      },
      set (value) {
        const sprinklerStore = useSprinklerStore();
        const winds = sprinklerStore.winds;
        winds[1] = value;
        if (winds[0] === false && winds[1] === false) {
          nextTick(() => {
            winds[1] = true;
          });
        } else {
          sprinklerStore.resetSprinklerSelection();
        }
      }
    }
  },
};
</script>

<style scoped>
.wind-options {
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}
.re-min {
  flex: 2;
}
</style>