<template>
  <div class="header-container">
    <AppTitle />
    <UserBadge class="badge" />
  </div>
</template>

<script>
import AppTitle from '@/components/header_bar_components/AppTitle.vue';
import UserBadge from '@/components/header_bar_components/UserBadge.vue';

export default {
  components: {
    AppTitle,
    UserBadge
  }
};
</script>

<style scoped>

.header-container {
  display: flex;
  height: 50px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.badge {
  margin-right: 10px;
}
</style>