import { defineStore } from 'pinia';

export const useModalStore = defineStore('modal', {
  state () {
    return {
      isShowing: false,
      pageY: 0,
      modalType: ''
    };
  },
  getters: {
    getIsShowing () { return this.isShowing; },
    getModalType () { return this.modalType; },
  },
  actions: {
    toggleModal (type) {
      this.modalType = type;
      this.isShowing = !this.isShowing;
      this.pageY = window.scrollY;
      this.setScrollingBehavior();
    },
    disableWheel (e) {
      e.preventDefault();
      e.stopImmediatePropagation();
      window.scrollTo(0, this.pageY);
    },
    showModal (type) {
      this.modalType = type;
      this.isShowing = true;
    },
    setModalBackground (bool) {
      this.background = bool;
    },
    hideModal () {
      this.isShowing = false;
      this.setScrollingBehavior();
    },
    setScrollingBehavior () {
      if (this.isShowing) {
        window.addEventListener('scroll', this.disableWheel);
      } else {
        window.removeEventListener('scroll', this.disableWheel);
      }
    }
  }
});