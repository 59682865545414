<template>
    <!-- set progressbar -->
    <vue-progress-bar></vue-progress-bar>
    <HeaderBar class="no-print" />
    <div id="dataGridHolder" class="data-grid-holder no-print">
      <DataInput />
      <SprinklerGrid />
    </div>
    <RecordList class="no-print"/>
    <PrintPopup />
</template>

<script>
import DataInput from '@/components/DataInput';
import HeaderBar from '@/components/HeaderBar';
import RecordList from '@/components/RecordList';
import SprinklerGrid from '@/components/SprinklerGrid';
import PrintPopup from '@/components/print/PrintPopup';

export default {
  components: {
    PrintPopup,
    DataInput,
    HeaderBar,
    RecordList,
    SprinklerGrid
  }
};
</script>

<style>
* {
  scrollbar-width: auto;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 11px;
  height: 11px;
}
*::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.0);
}
*::-webkit-scrollbar-thumb {
  margin: 2px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: inset 0 0 10px 10px #d0d0d0;
  border: solid 3px transparent;
}
</style>

<style scoped>
.data-grid-holder {
  display: flex;
  height: 65vh;
  width: 100%;
}
</style>