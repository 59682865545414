<template>
  <div class="print-data-block-holder">
    <div class="pdb-element">
      <div class="pdb-label">Job Title</div>
      <div class="pdb-title pdb-value">{{ getJobTitle }}</div>
   </div>
    <div class="pdb-element">
      <div class="pdb-label">Record</div>
      <div class="pdb-record pdb-value">{{ getRecordNum }}</div>
    </div>
    <div class="pdb-element">
      <div class="pdb-label">Date</div>
      <div class="pdb-date pdb-value"> {{ getRecordDate }}</div>
    </div>
  </div>
</template>

<script>
import { usePrintStore } from '@/stores/PrintStore';

export default {
  name: 'PrintDataBlock',
  props: [
    'record'
  ],
  computed: {
    getJobTitle () {
      return usePrintStore().getJobTitle;
    },
    getRecordNum () {
      return this.$props.record.RecNum;
    },
    getRecordDate () {
      return this.$props.record.CurrentDate;
    }
  }
};
</script>

<style scoped>
.print-data-block-holder {
  display: flex;
  flex-direction: column;
}
.pdb-element {
  display: flex;
  height: 22px;
  margin-left: 20px;
}
.pdb-label {
  font-weight: 600;
  width: 85px;
  display: flex;
  justify-content: flex-end;
  border-bottom: .5px solid #231F20;
  font-size: 85%;
  padding-right: 8px;
  border-right: .5px solid #231F20;
}
.pdb-record {
  color: #39B54A;
}
.pdb-date {
  color: #0088CA;
}
.pdb-value {
  font-weight: 600;
  display: flex;
  justify-content: flex-start;
  border-bottom: .5px solid #231F20;
  font-size: 85%;
  padding-left: 8px;
}
</style>